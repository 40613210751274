import React, { useState } from 'react';
import { Box, IconButton, Drawer, List, ListItem, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import Logo from '../ui/Logo';

const Navigation = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' },
  ];

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Common text style to ensure consistency
  const navTextStyle = {
    fontFamily: "'Integral CF', sans-serif !important",
    fontWeight: 800,
    fontSize: '1.8rem',
    padding: '0.5rem 0',
    transition: 'all 0.3s ease',
    '&:hover': {
      letterSpacing: '0.05em',
      opacity: 0.7,
    },
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '20px',
          left: { xs: '24px', sm: '32px', md: '48px' },
          zIndex: 1000,
        }}
      >
        <Logo size={36} />
      </Box>

      <Box
        sx={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: 1000,
        }}
      >
        <IconButton
          onClick={toggleDrawer(true)}
          sx={{
            color: theme === 'dark' ? '#f5f5f5' : '#121212',
            backgroundColor:
              theme === 'dark' ? 'rgba(18, 18, 18, 0.5)' : 'rgba(245, 245, 245, 0.5)',
            backdropFilter: 'blur(5px)',
            border: 'none',
            '&:hover': {
              backgroundColor:
                theme === 'dark' ? 'rgba(30, 30, 30, 0.8)' : 'rgba(230, 230, 230, 0.8)',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '350px' },
            backgroundColor: theme === 'dark' ? '#121212' : '#f5f5f5',
            color: theme === 'dark' ? '#f5f5f5' : '#121212',
            padding: '2rem',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2rem',
          }}
        >
          <Logo size={40} />
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{
              color: theme === 'dark' ? '#f5f5f5' : '#121212',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <List>
          {navLinks.map(link => (
            <ListItem
              key={link.name}
              disablePadding
              sx={{
                marginBottom: '1.5rem',
              }}
            >
              <Link
                to={link.path}
                style={{
                  color: theme === 'dark' ? '#f5f5f5' : '#121212',
                  textDecoration: 'none',
                  width: '100%',
                }}
                onClick={toggleDrawer(false)}
              >
                <Typography variant="h5" sx={navTextStyle}>
                  {link.name.toUpperCase()}
                </Typography>
              </Link>
            </ListItem>
          ))}

          <Divider
            sx={{
              my: 2,
              borderColor: theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
            }}
          />

          <ListItem
            disablePadding
            sx={{
              marginBottom: '1.5rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              toggleTheme();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                color: theme === 'dark' ? '#f5f5f5' : '#121212',
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {theme === 'dark' ? (
                  <LightModeIcon fontSize="large" />
                ) : (
                  <DarkModeIcon fontSize="large" />
                )}
              </Box>
              <Typography variant="h5" sx={navTextStyle}>
                {theme === 'dark' ? 'LIGHT MODE' : 'DARK MODE'}
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navigation;
