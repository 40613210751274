import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDocumentSetup } from '../hooks/useDocumentSetup';
import AnimatedText from '../components/ui/AnimatedText';
import MotionPage from '../components/ui/MotionPage';

const AboutPage = () => {
  useDocumentSetup('About - Marc Reyes');

  return (
    <MotionPage>
      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
          my: 0,
          pt: { xs: '15vh', md: '20vh' },
        }}
      >
        <Box
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            maxWidth: '1200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            px: { xs: 3, sm: 4, md: 6 },
            py: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              className="dramatic-title"
              sx={{
                fontFamily: "'Integral CF', sans-serif !important",
                fontSize: { xs: 'clamp(3rem, 15vw, 10rem)', md: 'clamp(5rem, 15vw, 12rem)' },
                lineHeight: '0.7',
                fontWeight: 900,
                textAlign: 'left',
                margin: 0,
                padding: 0,
                marginBottom: '10vh',
              }}
            >
              <AnimatedText text="ABOUT" startDelay={0} />
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Degular', sans-serif",
                fontSize: { xs: '1.1rem', md: '1.3rem' },
                lineHeight: '1.8',
                textAlign: 'left',
                maxWidth: '800px',
                opacity: 0.9,
                mt: '10vh',
                mb: 5,
              }}
            >
              AI Software Engineer specializing in production-ready machine learning systems.
              Professional experience at{' '}
              <a
                href="https://www.angkas.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: 'inherit',
                  textDecoration: 'underline',
                  textDecorationThickness: '1px',
                  textUnderlineOffset: '3px',
                  transition: 'all 0.3s ease',
                  display: 'inline-block',
                  position: 'relative',
                }}
                onMouseOver={e => {
                  e.currentTarget.style.textDecoration = 'none';
                }}
                onMouseOut={e => {
                  e.currentTarget.style.textDecoration = 'underline';
                  e.currentTarget.style.textUnderlineOffset = '3px';
                  e.currentTarget.style.textDecorationThickness = '1px';
                }}
              >
                Angkas
              </a>{' '}
              and{' '}
              <a
                href="https://www.gcash.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: 'inherit',
                  textDecoration: 'underline',
                  textDecorationThickness: '1px',
                  textUnderlineOffset: '3px',
                  transition: 'all 0.3s ease',
                  display: 'inline-block',
                  position: 'relative',
                }}
                onMouseOver={e => {
                  e.currentTarget.style.textDecoration = 'none';
                }}
                onMouseOut={e => {
                  e.currentTarget.style.textDecoration = 'underline';
                  e.currentTarget.style.textUnderlineOffset = '3px';
                  e.currentTarget.style.textDecorationThickness = '1px';
                }}
              >
                GCash
              </a>
              . Focused on building scalable AI solutions that balance technical performance with
              exceptional user experience.
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Degular', sans-serif",
                fontSize: { xs: '1.1rem', md: '1.3rem' },
                lineHeight: '1.8',
                textAlign: 'left',
                maxWidth: '800px',
                opacity: 0.9,
                mb: 5,
              }}
            >
              Cat lover, fitness enthusiast. Maintains balance through regular gym sessions and
              outdoor running to fuel creativity and problem-solving abilities.
            </Typography>
          </Box>
        </Box>
      </Box>
    </MotionPage>
  );
};

export default AboutPage;
