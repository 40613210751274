import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '../contexts/ThemeContext';
import { useDocumentSetup } from '../hooks/useDocumentSetup';
import AnimatedText from '../components/ui/AnimatedText';
import MotionPage from '../components/ui/MotionPage';

const NotFoundPage = () => {
  const { theme } = useTheme();
  useDocumentSetup('404 - Marc Reyes');

  return (
    <MotionPage>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          className="name-container"
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
            marginTop: { xs: '-10vh', sm: '-12vh', md: '-14vh' },
            pl: { xs: 3, sm: 4, md: 6 },
            ml: { xs: 0, sm: 0, md: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              className="dramatic-title"
              sx={{
                fontFamily: "'Integral CF', sans-serif !important",
                fontSize: { xs: 'clamp(4rem, 20vw, 25rem)', md: 'clamp(8rem, 25vw, 30rem)' },
                lineHeight: '0.6',
                fontWeight: 900,
                textAlign: 'left',
                margin: 0,
                padding: 0,
              }}
            >
              <AnimatedText text="OH NO" startDelay={0} />
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontFamily: "'Degular', sans-serif",
                fontSize: { xs: 'clamp(1rem, 5vw, 2.5rem)', md: 'clamp(1.5rem, 5vw, 3rem)' },
                lineHeight: '1.2',
                fontWeight: 400,
                textAlign: 'left',
                margin: 0,
                marginTop: '8vh',
                padding: 0,
                opacity: 0.8,
              }}
            >
              Page not found
            </Typography>
            <Link
              href="/"
              sx={{
                display: 'inline-block',
                color: theme === 'dark' ? '#f5f5f5' : '#121212',
                textDecoration: 'none',
                fontSize: { xs: '1rem', sm: '1.2rem' },
                marginTop: '3vh',
                borderBottom: `1px solid ${theme === 'dark' ? '#f5f5f5' : '#121212'}`,
                transition: 'all 0.3s ease',
                '&:hover': {
                  letterSpacing: '0.03em',
                  borderBottom: `1px solid transparent`,
                },
              }}
            >
              Return Home
            </Link>
          </Box>
        </Box>
      </Box>
    </MotionPage>
  );
};

export default NotFoundPage;
