import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { ReactComponent as LogoSVG } from '../../logo.svg';

const Logo = ({ size = 40, linkTo = '/' }) => {
  const { theme } = useTheme();

  return (
    <Box 
      component={Link} 
      to={linkTo}
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          '& svg': {
            width: '100%',
            height: '100%',
            transition: 'fill 0.3s ease',
            color: theme === 'dark' ? '#f5f5f5' : '#121212',
          }
        }}
      >
        <LogoSVG />
      </Box>
    </Box>
  );
};

export default Logo; 