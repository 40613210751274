import React from 'react';
import { Container } from '@mui/material';
import { useTheme } from '../../contexts/ThemeContext';
import Footer from './Footer';

const MainLayout = ({ children }) => {
  const { theme } = useTheme();

  return (
    <Container
      className="App"
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: theme === 'dark' ? '#121212' : '#f5f5f5',
        color: theme === 'dark' ? '#f5f5f5' : '#121212',
        overflowX: 'hidden',
        position: 'relative',
        p: 0,
      }}
    >
      {children}
      <Footer />
    </Container>
  );
};

export default MainLayout; 