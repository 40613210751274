import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '../contexts/ThemeContext';
import { useDocumentSetup } from '../hooks/useDocumentSetup';
import AnimatedText from '../components/ui/AnimatedText';
import MotionPage from '../components/ui/MotionPage';

const ContactPage = () => {
  const { theme } = useTheme();
  useDocumentSetup('Contact - Marc Reyes');

  const contactLinks = [
    { name: 'Email', url: 'mailto:hi@marcr.xyz', label: 'hi@marcr.xyz' },
    { name: 'GitHub', url: 'https://github.com/mabreyes', label: 'github.com/mabreyes' },
    {
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/mabreyes',
      label: 'linkedin.com/in/mabreyes',
    },
  ];

  return (
    <MotionPage>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          my: 0,
        }}
      >
        <Box
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            maxWidth: '1200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            px: { xs: 3, sm: 4, md: 6 },
            py: 0,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                marginBottom: '10vh',
                overflow: 'visible',
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                className="dramatic-title"
                sx={{
                  fontFamily: "'Integral CF', sans-serif !important",
                  fontSize: { xs: 'clamp(2.5rem, 12vw, 9rem)', md: 'clamp(4rem, 12vw, 10rem)' },
                  lineHeight: '0.7',
                  fontWeight: 900,
                  textAlign: 'left',
                  margin: 0,
                  padding: 0,
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                }}
              >
                <AnimatedText text="CONTACT" startDelay={0} />
              </Typography>
            </Box>

            <Typography
              variant="body1"
              component="p"
              sx={{
                fontFamily: "'Degular', sans-serif",
                fontSize: { xs: '1.1rem', md: '1.3rem' },
                lineHeight: '1.8',
                textAlign: 'left',
                maxWidth: '800px',
                opacity: 0.9,
                mt: 0,
                mb: 6,
              }}
            >
              I'm always open to new projects and collaborations. Feel free to reach out through any
              of the following channels:
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                width: '100%',
                maxWidth: '500px',
                mt: 0,
              }}
            >
              {contactLinks.map((link, index) => (
                <Box
                  key={link.name}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    width: '100%',
                    borderBottom: `1px solid ${theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
                    pb: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{
                      fontFamily: "'Integral CF', sans-serif !important",
                      fontSize: { xs: '1.2rem', sm: '1.4rem' },
                      fontWeight: 800,
                      opacity: 1,
                      letterSpacing: '0.05em',
                      textTransform: 'uppercase',
                    }}
                  >
                    {link.name}
                  </Typography>
                  <Link
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: theme === 'dark' ? '#f5f5f5' : '#121212',
                      textDecoration: 'none',
                      fontSize: { xs: '1rem', sm: '1.1rem' },
                      fontFamily: "'Degular', sans-serif",
                      transition: 'all 0.3s ease',
                      mt: { xs: 1, sm: 0 },
                      '&:hover': {
                        letterSpacing: '0.03em',
                        opacity: 0.7,
                      },
                    }}
                  >
                    {link.label}
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </MotionPage>
  );
};

export default ContactPage;
