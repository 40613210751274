import React, { createContext, useState, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    // Check system preference for initial theme
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    
    // Check if user has a saved preference
    const savedTheme = localStorage.getItem('theme');
    
    // Set theme based on saved preference or system preference
    setTheme(savedTheme || systemTheme);

    // Listen for system theme changes
    const handleThemeChange = (e) => {
      // Only update if user hasn't set a preference
      if (!localStorage.getItem('theme')) {
        setTheme(e.matches ? 'dark' : 'light');
      }
    };

    const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMediaQuery.addEventListener('change', handleThemeChange);

    return () => {
      darkThemeMediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, []);

  // Update body class and localStorage when theme changes
  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeContext; 