import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDocumentSetup } from '../hooks/useDocumentSetup';
import AnimatedText from '../components/ui/AnimatedText';
import MotionPage from '../components/ui/MotionPage';

const HomePage = () => {
  useDocumentSetup('Marc Reyes');

  return (
    <MotionPage>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          className="name-container"
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
            marginTop: { xs: '-10vh', sm: '-12vh', md: '-14vh' },
            pl: { xs: 3, sm: 4, md: 6 },
            ml: { xs: 0, sm: 0, md: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              className="dramatic-title"
              sx={{
                fontFamily: "'Integral CF', sans-serif !important",
                fontSize: { xs: 'clamp(4rem, 20vw, 25rem)', md: 'clamp(8rem, 25vw, 30rem)' },
                lineHeight: '0.6',
                fontWeight: 900,
                textAlign: 'left',
                margin: 0,
                padding: 0,
              }}
            >
              <AnimatedText text="MARC" startDelay={0} />
            </Typography>
            <Typography
              variant="h1"
              component="h1"
              className="dramatic-title"
              sx={{
                fontFamily: "'Integral CF', sans-serif !important",
                fontSize: { xs: 'clamp(4rem, 20vw, 25rem)', md: 'clamp(8rem, 25vw, 30rem)' },
                lineHeight: '0.6',
                fontWeight: 900,
                textAlign: 'left',
                margin: 0,
                marginTop: { xs: '-8vh', sm: '-9vh', md: '-10vh' },
                padding: 0,
              }}
            >
              <AnimatedText text="REYES" startDelay={0.25} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </MotionPage>
  );
};

export default HomePage;
