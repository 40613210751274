import React from 'react';

const AnimatedText = ({ text, startDelay = 0 }) => {
  return (
    <>
      {text.split('').map((letter, index) => (
        <span 
          key={index} 
          className="letter-animation"
          style={{ 
            display: letter === ' ' ? 'inline-block' : 'inline-block',
            fontFamily: "'Integral CF', sans-serif !important",
            fontWeight: 900,
            padding: 0,
            margin: 0,
            animationDelay: `${startDelay + (index * 0.05)}s`
          }}
        >
          {letter === ' ' ? '\u00A0' : letter}
        </span>
      ))}
    </>
  );
};

export default AnimatedText; 