import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '../../contexts/ThemeContext';
import Logo from '../ui/Logo';

const Footer = () => {
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        paddingLeft: { xs: '24px', sm: '32px', md: '48px' },
        paddingRight: { xs: '24px', sm: '32px', md: '48px' },
        paddingTop: { xs: 3, sm: 4 },
        paddingBottom: { xs: 3, sm: 4 },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          transformStyle: 'preserve-3d',
          opacity: 0.7,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Logo size={32} />
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' },
            fontWeight: 400,
            letterSpacing: '-0.02em',
            transform: 'translateZ(5px)',
            textAlign: 'left',
            color: theme === 'dark' ? '#f5f5f5' : '#121212',
          }}
        >
          AI Software Engineer
        </Typography>
      </Box>

      <Box
        sx={{
          transformStyle: 'preserve-3d',
          opacity: 0.7,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Link
          href="mailto:hi@marcr.xyz?subject=Hello Marc"
          sx={{
            display: 'inline-block',
            color: theme === 'dark' ? '#f5f5f5' : '#121212',
            textDecoration: 'none',
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' },
            borderBottom: `1px solid ${theme === 'dark' ? '#f5f5f5' : '#121212'}`,
            transition: 'all 0.3s ease',
            transform: 'translateZ(0)',
            position: 'relative',
            '&:hover': {
              borderBottom: `1px solid transparent`,
              transform: 'translateZ(10px)',
            },
          }}
        >
          Get in touch
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
