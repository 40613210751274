import React from 'react';
import { motion } from 'framer-motion';

// Animation variants for page transitions
const pageVariants = {
  initial: {
    opacity: 0,
    y: -20,
    filter: 'blur(10px)',
  },
  in: {
    opacity: 1,
    y: 0,
    filter: 'blur(0px)',
  },
  exit: {
    opacity: 0,
    y: -20,
    filter: 'blur(10px)',
  },
};

// Timing for animations
const pageTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.3,
};

const MotionPage = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
      style={{ width: '100%', height: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export default MotionPage;
